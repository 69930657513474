/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Después de mi webcam me propuse instalarme la tarjeta USB Wifi que tenía, así que me puse manos a la obra:"), "\n", React.createElement(_components.p, null, "Primero que nada hay que saber que chipset tienes, yo como ya había trasteado con esta tarjeta y la tenia abierta pude ver un chip grande donde ponía rt2571F, me informé un poco y descubrí que tenia que instalar el driver rt2570. Así que abrí Symaptic y busque, no encontré un paquete precompilado, lastima , pero encontré el código fuente, algo es algo, lo bajé y lo instale de la siguiente forma:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">module-assistant prepare\nmodule-assistant get rt2570\nmodule-assistant build rt2570\n<span class=\"token builtin class-name\">cd</span> /usr/src</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "(Aquí hice un ls para saber que versión tenia que poner en el siguiente comando)"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">dpkg <span class=\"token parameter variable\">-i</span> rt2570-modules-<span class=\"token operator\">&lt;</span>versión<span class=\"token operator\">></span>.deb</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y ya tenemos instalado nuestro driver, ahora hay que asegurarse de que funciona bien, para esto utilicé kismet, es un programa parecido a NetStumbler pero mas completo y para cualquier SO."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">apt-get</span> <span class=\"token function\">install</span> kismet</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Hay que configurarlo:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">gedit /etc/kismet/kismet.conf</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y editamos la linea ", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">source=...</code>"
    }
  }), " cambiándola por esta:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">source=rt2500,eth1,rt2500</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Así indicamos a kismet que driver usamos (si aquí hay que poner rt2500 porque el rt2570 no lo reconoce) y el nombre de la interface."), "\n", React.createElement(_components.p, null, "Una vez echo esto ya podemos ejecutar kismet."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">kismet</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y comprobar que nuestro driver funciona."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
